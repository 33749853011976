@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500&family=Oswald:wght@200;300;400;500;600;700&display=swap');
$font-family-sans-serif: 'Oswald', 'Noto', Arial, sans-serif;


//Colors
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$gray-icml: #575757;

$blue:    #0014b4;
$indigo:  #1e00a5;
$purple:  #3d00a5;
$pink:    #9500a5;
$red:     #e40617;
$orange:  #a54a00;
$yellow:  #d7a500;
$green:   #42a500;
$teal:    #00a573;
$cyan:    #0097a5;

$blue-light: #B5CFE8;

$primary:       $red !default;
$secondary:     $blue !default;
$third:         $gray-icml !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;

//GRID
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1460px
);
$container-padding-x:       1.5rem * 2;

$font-size-base:              1rem;
$border-radius:               0rem;
$body-color:                  $gray-900;

$border-color:              $primary;

$font-size-base:            3rem;
$line-height-base:          1.25;

$border-width:              10px;

$border-radius:               0;
$border-radius-sm:            0;
$border-radius-lg:            0;
$border-radius-xl:            0;
$border-radius-2xl:           0;
$border-radius-pill:          0;