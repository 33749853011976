
$font-weight-extra: 200;
$font-weight-light: 300;
$font-weight-bold: 700;

$gray-base: #575757;
$white: #fff;
$red: #e40617;
$blue: #0014b4;

.font-extra{
  font-weight: $font-weight-extra;
}
.font-light{
  font-weight: $font-weight-light;
}
.font-bold{
  font-weight: $font-weight-bold;
}

//size icon
.ico-size-1{
  font-size: 0.8rem;
}
.ico-size-2{
  font-size: 1.2rem;
}
.ico-size-3{
  font-size: 1.4rem;
}

//color
.text-gray{
  color: $gray-base;

  &.border-bottom{
    border-bottom: 1px solid $gray-base !important;
  }
  &.border-top{
    border-top: 1px solid $gray-base !important;
  }
  &.border-right{
    border-right: 1px solid $gray-base !important;
  }
  &.border-left{
    border-left: 1px solid $gray-base !important;
  }
}
.text-blue{
  color: $blue;

  &.border-bottom{
    border-bottom: 1px solid $blue !important;
  }
  &.border-top{
    border-top: 1px solid $blue !important;
  }
  &.border-right{
    border-right: 1px solid $blue !important;
  }
  &.border-left{
    border-left: 1px solid $blue !important;
  }
}
.text-red{
  color: $red;

  &.border-bottom{
    border-bottom: 1px solid $red !important;
  }
  &.border-top{
    border-top: 1px solid $red !important;
  }
  &.border-right{
    border-right: 1px solid $red !important;
  }
  &.border-left{
    border-left: 1px solid $red !important;
  }

}

//link color
.link-red{
  color: $red !important;
  &:hover{
    color: darken($red, 15%) !important;
  }
}
.link-blue{
  color: $blue !important;
  &:hover{
    color: darken($blue, 15%) !important;
  }
}
.link-gray{
  color: $gray-base !important;
  &:hover{
    color: darken($gray-base, 15%) !important;
  }
}

//box color
@mixin icon-box($color) {
  border: 20px solid $color;
}
.item-box-border-gray{
  @include icon-box($gray-base);
}
.item-box-border-red{
  @include icon-box($red);
}
.item-box-border-blue{
  @include icon-box($blue);
}

@mixin icon-box-link($color) {
  border: 3px solid $color;
  color: $color;
  @media screen and (min-width: 768px) {
    border-width: 4px;
  }
  &:hover, &.active{
    color: $white;
    background: $color;
    text-decoration: none;
    .icon-circle{
      color: #fff;
      background: $color;
    }
  }
  .icon-circle{
    border: 3px solid $color;
    border-radius: 100%;
    padding: 10px;
    @media screen and (min-width: 768px) {
      border-width: 4px;
    }
  }
}

a.item-box-border-gray{
  @include icon-box-link($gray-base);
}
a.item-box-border-red{
  @include icon-box-link($red);
}
a.item-box-border-blue{
  @include icon-box-link($blue);
}
//inline-video
@mixin inline-video($color){
  border-top: 2px solid $color;
  a {
    color: $color;
    > .icon-circle{
      border: 2px solid $color;
      border-radius: 100%;
      padding: 5px;
      display: inline-block;
    }
    &:hover, &.active{
      > .icon-circle{
        background: $color;
        color: $white;
      }
    }
  }
}
.video-content-red{
  @include inline-video($red);
}
.video-content-blue{
  @include inline-video($blue);
}
.video-content-gray{
  @include inline-video($gray-base);
}

//line top
@mixin inline-video($color){
  border-top: 2px solid $color;
}
.line-red{
  @include inline-video($red);
}
.line-blue{
  @include inline-video($blue);
}
.line-gray{
  @include inline-video($gray-base);
}
