body{
    letter-spacing: -0.125rem;
}
$margin-platform: 2rem;

.content-all{
    margin: $margin-platform 0;
    width: 100%;
    height: calc(100vh - 4rem);
}
.content-split-two{
    display: grid;
    height: calc(100% - 2rem);
    grid-template-rows: repeat(2, 50%);
    grid-template-columns: auto;
    grid-row-gap: 2rem;
}
.image-scan{
    height: 40vh;
    width: auto;
}
p{
    line-height: 3.75rem;
}
.main-title{
    font-size: 30vh;
    &-end{
        font-size: 17vh;
    }
}

.content-alert{
    max-width: 50vw;
    p{
        font-size: 3.5rem;
    }
}
.confirm-information{
    p{
        font-size: 3.25rem;
    }
}
.button-validate{
    button{
        font-size: 4.5rem;
    }
}
.all-validation{
    width: 100%;
    max-width: 87vw;
}
.info-badge{
    bottom: 0;
    padding: 3rem;
}

.content-loader{
    width: 100%;
    max-width: 50vw;
    p{
        font-size: 3.75rem;
        line-height: 4.25rem;
    }
    .content-text{
        padding: 3rem 3.5rem;
        top: 0;
        left: 0;
    }
}

.btn-large{
    font-size: 6rem;
    padding-left: 4rem;
    padding-right: 4rem;
    border-width: 15px;
}

.custom-input{
    background: rgba(184, 184, 184, 0.50);
    color: rgba(0,0,0,0.5);
    border: none;
    appearance: none;
    border: 0;
    padding: 10px 15px;
    font-size: 1.2vw;
    width: 100%;
    max-width: 30vw;
    &:focus-visible, &:focus{
        outline: none;
    }
}