.cube{
  &-red{
    fill: #e40617;
  }
  &-black{
    fill: #1d1d1b;
  }
}
.border-loader{
  width: 100%;
  height: auto;
}

$delay-animation: 0.5s;

@keyframes delayChangeColor {
  0%, 100%{
    fill:  #1d1d1b;
  }
  1%, 99% {
    fill: #e40617;
  }
}

@for $i from 0 through 55 {
  #cube-#{$i} {
    animation:  delayChangeColor 0.5s linear $delay-animation * $i forwards,
                delayChangeColor 0.5s linear $delay-animation * $i + 56 * $delay-animation forwards,
                delayChangeColor 0.5s linear $delay-animation * $i + 112 * $delay-animation forwards
  }
}